import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChildForm from './Children';

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/child/:id" element={<ChildForm />} />
      {/* Ajoutez d'autres routes ici si nécessaire */}
      </Routes>
    </Router>
  );
};

export default App;
