// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';

// const ChildForm = () => {
//   const { id } = useParams(); // Récupérer l'ID depuis l'URL
//   const [formData, setFormData] = useState({
//     nom_personne: '',
//     numeros: '',
//     description: '',
//     position: '',
//   });
//   const [location, setLocation] = useState({ lat: '', lng: '' });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');

//   // Utiliser l'API de géolocalisation du navigateur pour obtenir la position
//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setLocation({ lat: latitude, lng: longitude });
//           setFormData((prevData) => ({
//             ...prevData,
//             position: `${latitude}, ${longitude}`,
//           }));
//         },
//         (error) => {
//           switch (error.code) {
//             case error.PERMISSION_DENIED:
//               setError('Permission refusée pour accéder à la géolocalisation.');
//               break;
//             case error.POSITION_UNAVAILABLE:
//               setError('La position géographique n\'est pas disponible.');
//               break;
//             case error.TIMEOUT:
//               setError('Le délai de récupération de la position a expiré.');
//               break;
//             default:
//               setError('Une erreur inconnue est survenue lors de la géolocalisation.');
//           }
//         }
//       );
//     } else {
//       setError('La géolocalisation n\'est pas supportée par votre navigateur.');
//     }
//   }, []);
  

//   // Gérer les changements de formulaire
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   // Gérer la soumission du formulaire
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError('');
//     setSuccess('');

    

//     try {
//       const response = await fetch('http://localhost:8000/api/alerts', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           qr_code_id: id, // Utiliser l'ID récupéré depuis l'URL
//           id_child: id,
//           nom_personne: formData.nom_personne,
//           position: formData.position,
//           numeros: formData.numeros,
//           description: formData.description,
//           location: JSON.stringify({ lat: location.lat, lng: location.lng })
//         }),
//       });
//         console.log(response)
//       if (response.ok) {
//         setSuccess('Alerte créée avec succès.');
//         setFormData({
//           nom_personne: '',
//           numeros: '',
//           description: '',
//           position: '',
//         });
//       } else {
//         throw new Error('Erreur lors de la création de l\'alerte.');
//       }
//     } catch (err) {
//       setError('Erreur lors de la création de l\'alerte. Veuillez réessayer.');
//       console.error('Erreur lors de la soumission du formulaire :', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="app-container">
//       <h1>Formulaire de Signalement</h1>
//       {error && <p className="error-message">{error}</p>}
//       {success && <p className="success-message">{success}</p>}

//       <form className="report-form" onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label>Nom :</label>
//           <input
//             type="text"
//             name="nom_personne"
//             value={formData.nom_personne}
//             onChange={handleChange}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label>Numéro de téléphone :</label>
//           <input
//             type="tel"
//             name="numeros"
//             value={formData.numeros}
//             onChange={handleChange}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label>Description :</label>
//           <textarea
//             name="description"
//             value={formData.description}
//             onChange={handleChange}
//             placeholder="Ajoutez des détails sur la situation"
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label>Position géographique :</label>
//           <input
//             type="text"
//             name="position"
//             value={formData.position}
//             readOnly
//           />
//         </div>

//         <button type="submit" className="submit-button" disabled={loading}>
//           {loading ? 'Envoi...' : 'Envoyer le signalement'}
//         </button>
//       </form>
//     </div>
//   );
// };

// export default ChildForm;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './css/childrenForm.css'
const ChildForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    nom_personne: '',
    numeros: '',
    description: '',
    position: '',
    quartier: '', // Nouveau champ pour le quartier
  });
  const [location, setLocation] = useState({ lat: '', lng: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Liste des quartiers
  const quartiers = [
    "Abobo", "Adjamé", "Attecoubé", "Cocody", "Koumassi", "Marcory", "Plateau", 
    "Port-Bouët", "Treichville", "Yopougon", "Angré", "Bingerville", "Anyama",
    // Ajoutez d'autres quartiers si nécessaire
  ];

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });
          setFormData((prevData) => ({
            ...prevData,
            position: `${latitude}, ${longitude}`,
          }));
        },
        (error) => {
          setError('Erreur lors de la géolocalisation.');
        }
      );
    } else {
      setError("La géolocalisation n'est pas supportée par votre navigateur.");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch('https://api.kidysafe.com/api/alerts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qr_code_id: id,
          id_child: id,
          nom_personne: formData.nom_personne,
          position: formData.position,
          numeros: formData.numeros,
          description: formData.description,
          quartier: formData.quartier,
          location: JSON.stringify({ lat: location.lat, lng: location.lng })
        }),
      });

      if (response.ok) {
        setSuccess('Alerte créée avec succès.');
        setFormData({
          nom_personne: '',
          numeros: '',
          description: '',
          position: '',
          quartier: '', // Réinitialise le champ quartier
        });
      } else {
        throw new Error('Erreur lors de la création de l\'alerte.');
      }
    } catch (err) {
      setError('Erreur lors de la création de l\'alerte. Veuillez réessayer.');
      console.error('Erreur lors de la soumission du formulaire :', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app-container">
      <h1>Formulaire de Signalement</h1>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}

      <form className="report-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nom :</label>
          <input
            type="text"
            name="nom_personne"
            value={formData.nom_personne}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Numéro de téléphone :</label>
          <input
            type="tel"
            name="numeros"
            value={formData.numeros}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Description :</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Ajoutez des détails sur la situation"
            required
          />
        </div>

        <div className="form-group">
          <label>Quartier :</label>
          <select
            name="quartier"
            value={formData.quartier}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez un quartier</option>
            {quartiers.map((quartier, index) => (
              <option key={index} value={quartier}>
                {quartier}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Position géographique :</label>
          <input
            type="text"
            name="position"
            value={formData.position}
            readOnly
          />
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Envoi...' : 'Envoyer le signalement'}
        </button>
      </form>
    </div>
  );
};

export default ChildForm;
